.about2 {
	margin-top: 80px;
	margin-bottom: 90px;
	
}
.title {
	color: var(--main, #000);
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.mt24px {
	margin-top: 24px;
}

.about2-title-2 {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 60px;
}

.about2-text-2-block {
	display: flex;
	justify-content: space-between;
	gap: 32px;
}
.job-title {
	color: var(--main, #000);
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;
	margin-top: 24px;
}
.job-text{
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
	margin-top: 24px;
}

.ul{
	margin-left: 50px;
}
