.waiters-description {
	margin-top: 80px;
}

.waiters-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.waiters-text {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
.mt24px {
	margin-top: 24px;
}
.waiters-text ul {
	margin-left: 40px;
}
.staff-section1-title-2 {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.staff-section1-text-2 {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
