.section8 {
	margin-top: 80px;
}
.section8-flex-block{
	
}

.section8-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.section8-card {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 44px;
}
.section8-card-item {
	width: 367px;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 20px;
	font-weight: bold;
	padding: 24px 47px 24px 48px;
	border-radius: 20px;
	background: #f9f9f9;
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%;
}
