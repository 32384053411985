.staff-section1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 80px;
}

.staff-section1-block {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 500px;
}
.staff-section1-title {
	color: var(--main, #000);
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.staff-section1-text {
	color: var(--subMain, #67686a);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
}
