@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('./fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('./fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('./fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('./fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: Montserrat;
	line-height: 1.6;
}

.home {
	padding: 20px 0;
}

.home h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.home p {
	font-size: 1.2rem;
}

.about {
	padding: 20px 0;
}

.about h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.about p {
	font-size: 1.2rem;
}
.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-width: 1180px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

.header {
	background: #fff;
	height: 80px;
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-shrink: 0;
}

.main-content {
	flex: 1;
	height: 100%;
}

.footer {
	background-color: rgba(40, 40, 40, 1);
	color: rgba(254, 254, 254, 1);
	padding: 40px;
	width: 100%;
	height: 100%;
	flex-shrink: 0;
}
.page-background {
	background-color: rgba(247, 247, 250, 1);
	min-height: 100vh;
	padding: 50px;
}

.page-container {
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
}
.page-title {
	color: rgba(12, 18, 42, 1);
	font-size: 32px;
	font-weight: 700;
}
.content-container {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 20px;
}

.btn-blue {
	cursor: pointer;
	display: flex;
	padding: 16px 44px;
	justify-content: center;
	align-items: center;
	border-radius: 99px;
	background: var(
		--gradient_1,
		linear-gradient(323deg, #1183c3 19.51%, #07d2fd 80.8%)
	);
	border: none;

	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.btn-green {
	cursor: pointer;
	display: flex;
	padding: 16px 44px;
	justify-content: center;
	align-items: center;
	border-radius: 99px;
	background: var(--Success, #4fa21e);
	border: none;

	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.btn-white {
	border-radius: 99px;
	background: #fff;
	display: flex;
	padding: 16px 44px;
	justify-content: center;
	align-items: center;
	border: none;

	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.mt24px {
	margin-top: 24px;
}
a {
	text-decoration: none;
	color: inherit;
}
