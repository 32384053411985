.section3 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}

.section3::before {
	content: '';
	position: absolute;
	top: 87px;
	left: 50%;
	transform: translateX(-50%);
	width: 120%;
	height: 368px;
	border-radius: 20px;
	background: #f9f9f9;
	z-index: -1;
}

.section1-block {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 500px;
}
.section1-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.section1-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
}
