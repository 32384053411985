.how-section2 {
	margin-top: 80px;
}

.how-section2-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 46.08px */
}

.register-block {
	margin-top: 80px;
}
.register-block {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.register-block-description {
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.register-block-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.register-block-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
.qr-code-block {
	margin-top: 80px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.qr-code-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.qr-code-descr {
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.qr-code-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}

.qr-code-title-2 {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 144%; /* 28.8px */
}
