.section5 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	margin-top: 80px;
}

.section5::before {
	content: '';
	position: absolute;
	top: 54px;
	left: 50%;
	transform: translateX(-50%);
	width: 120%;
	height: 371px;
	border-radius: 20px;
	background: #f9f9f9;
	z-index: -1;
}

.section5-block {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 400px;
}
.section5-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.section5-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
}
