.qr {
	margin-top: 60px;
	display: flex;
	justify-content: space-between;
	gap: 60px;
	margin-bottom: 80px;
}

.qr-block,
.qr-transactions-block {
	flex: 1 1 0;
	width: 100%;
}
.qr-transactions-block {
	display: flex;
	padding: 70px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
	align-self: stretch;
	border-radius: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
	height: 550px;
}

.transactions-title {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.transactions-text {
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	margin-top: 24px;
}

.qr-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
}
.qr-number {
	color: var(--main, #000);
	text-align: center;
	font-family: Montserrat;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 144%; /* 46.08px */
}
.qr-text {
	color: var(--main, #000);
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	text-decoration-line: underline;
}

.qr-url {
	display: flex;
	width: 379px;
	height: 39px;
	padding: 8px 0px;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid var(--subMain, #67686a);
	position: relative;
}
.qr-copy {
	position: absolute;
	right: 0;
	top: 10px;
	cursor: pointer;
}
.qr-url-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 350px;
}
