.title-transactions {
	color: var(--main, #000);
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.tran {
	margin-bottom: 60px;
}

.transactions-section,
.payouts-section {
	margin: 20px 0;
	padding: 20px;
	border-radius: 12px;
	background: #fff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

h2 {
	font-size: 24px;
	margin-bottom: 20px;
}

.transactions-filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 60px;
}

.type-block {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 20px;
	gap: 30px;
}

.transaction-type label {
	color: var(--subMain, #67686a);
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 14.4px */
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
	color: #4fa21e !important;
}

.transaction-period {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 340px;
	margin-top: 15px;
}

.transaction-period input {
	margin-left: 10px;
	padding: 5px;
}

/* Стили таблиц */
.transactions-table,
.payouts-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

.transactions-table th,
.transactions-table td,
.payouts-table th,
.payouts-table td {
	text-align: left;
	padding: 12px;
	border-bottom: 1px solid #eee;
}

.transactions-table th,
.payouts-table th {
	font-weight: bold;
}

.transactions-table tr:last-child td,
.payouts-table tr:last-child td {
	border-bottom: none;
}

/* Кнопка оплаты */
.MuiButton-containedSuccess {
	background-color: #4caf50;
}

.MuiButton-containedSuccess:hover {
	background-color: #45a049;
}
.btn-tran {
	display: flex;
	padding: 12px 24px;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 99px;
	background: var(--Success, #4fa21e);
	color: #fff;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border: none;
}

button {
	cursor: pointer;
}

.dialog-img-close {
	display: inline-flex;
	justify-content: flex-end;
	cursor: pointer;
}

.dialog-title {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.dialog-text {
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	margin-top: 24px;
}

.dialog-btn{
	margin-top: 24px;
	display: flex;
	justify-content: center;
}