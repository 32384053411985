

.header2 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.nav-item2 {
		display: flex;
		gap: 20px;
	}

	.dropdown {
		position: relative;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: white;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
		min-width: 160px;
		z-index: 1;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.dropdown-content a {
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}

	.dropdown-content a:hover {
		background-color: #ddd;
	}
}

.header2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
}
.nav-item2 {
	display: flex;
	gap: 20px;

	a {
		text-decoration: none;
		color: #000;
		padding: 0 10px;
		&.active-link {
			color: #1183c3;
		}
	}
}