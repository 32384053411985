.terms-2 {
	margin-top: 100px;
	margin-bottom: 60px;
}

.terms-title-2 {
	color: var(--main, #000);
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.terms-contract-block {
	margin-top: 32px;
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 144%; /* 34.56px */
	width: 500px;
}
.terms-contract-descr {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
.descr-block {
	margin-top: 16px;
}
.terms-title-3 {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 144%; /* 34.56px */
	margin-top: 16px;
}
.terms-text {
	margin-top: 16px;
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
