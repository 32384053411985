.account {
	margin-top: 20px;
}
.account-block {
	display: flex;
	height: 88px;
	padding: 20px 85px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 20px;
	background: #f9f9f9;
}

.account-name {
	display: flex;
	align-items: center;
	gap: 10px;

	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.balance-text {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.balance-text-money {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.personalAccount {
	margin-top: 60px;
}
.personalAccount {
	display: flex;
	gap: 100px;
}
.account-block1 {
	width: 100%;
}

.account-block2 {
	margin-top: 50px;
	width: 100%;
}
.account-block2-text {
	color: var(--subMain, #67686a);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	margin-top: 24px;
}

.account-verify {
	margin-top: 60px;
}
.step1 {
	margin-top: 50px;
	margin-bottom: 50px;
}
.step1-block {
	display: flex;
	padding: 70px;
	align-items: flex-start;
	gap: 40px;
	align-self: stretch;
	border-radius: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.step1-block-left {
	width: 100%;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}
.file-input {
	display: none;
}

.block-text ul {
	margin-left: 50px;
	margin-top: 15px;
}
.btn-green-account {
	width: 100%;

	cursor: pointer;
	display: flex;
	padding: 16px 44px;
	justify-content: center;
	align-items: center;
	border-radius: 99px;
	background: var(--Success, #4fa21e);
	border: none;

	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}


.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
	color: #07D2FD!important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
	color: #07D2FD!important;
}
.verify-end{
	text-align: center;
}
.end-block{
	text-align: center;
	margin-top: 100px;
}