.section9 {
	margin-top: 80px;
}

.section9-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 32px;
	margin-top: 44px;
}
.section9-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.section9-item {
	display: flex;
	width: 550px;
	padding: 35px;
	align-items: center;
	gap: 35px;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
}
.section9-item-title {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%;
}

.section9-item-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%;
	margin-top: 10px;
}
