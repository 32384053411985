.section2 {
	margin-top: 80px;
}
.section2-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.section2-icon {
	display: flex;
	justify-content: space-between;
	margin-top: 60px;
}
.section2-icon-text {
	img {
		position: relative;
		top: 30px;
		left: 30px;
	}
}

.section2-icon-text-block {
	color: var(--main, #000);
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	span {
		color: var(--Highlight, #07d2fd);
		text-align: center;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-right: 10px;
	}
}
.section2-card-block {
	display: flex;
	padding: 48px 40px 24px 40px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	border-radius: 16px;
	background: #fff;
	box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
	width: 320px;
	z-index: -1000;
}
.bg {
	position: relative;
	width: 320px;
	height: 96px;
	bottom: 88px;
	border-radius: 16px;
	background: var(
		--gradient_1,
		linear-gradient(323deg, #1183c3 19.51%, #07d2fd 80.8%)
	);
	z-index: -1000;
}
