.business-section1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 80px;
}

.business-section1-block {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 500px;
}
.business-section1-title {
	color: var(--main, #000);
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.business-section1-text {
	color: var(--subMain, #67686a);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
}
.title2 {
	color: var(--main, #000);
	text-align: center;
	font-family: Montserrat;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 46.08px */
	margin-top: 100px;
}
.descr {
	margin-top: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 60px;
}
.descr-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.descr-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
	margin-top: 12px;
}

.mt24px	{
	margin-top: 24px;
}
.business-descr-rest{
	margin-top: 120px;
}
.descr-text ul {
	margin-left: 50px;
}
.mb50px{
	margin-bottom: 60px;
}