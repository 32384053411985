.section11 {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 20px;
	margin-top: 80px;
}

.section11::before {
	content: '';
	position: absolute;
	top: 75px;
	left: 50%;
	transform: translateX(-50%);
	width: 120%;
	height: 400px;
	border-radius: 20px;
	background: var(
		--gradient_1,
		linear-gradient(323deg, #1183c3 19.51%, #07d2fd 80.8%)
	);
	z-index: -1;
}

.section11-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.section11-title {
	color: #fff;
	text-align: center;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.section11-text {
	color: #fff;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
