.policy {
	margin-top: 100px;
	margin-bottom: 60px;
}
.policy-title {
	color: var(--main, #000);
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.policy-title-2 {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 144%; /* 34.56px */
	margin-top: 32px;
}

.mt16px	{
	margin-top: 16px;
}

.policy-ul{
	margin-left: 50px;
}
.terms{
	color: blue;
}
.policy-container{
	margin-bottom: 50px;
}