.faq-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 80px;
	margin-bottom: 30px;
}
.question {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 34.56px */
}
.answer {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 28.8px */
}
