.section10 {
	margin-top: 100px;
}

.section10-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.section10-block {
	margin-top: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 60px;
}
.section10-block-title {
	color: var(--main, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 34.56px */
}
.section10-block-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */
}
.section10-block-description {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 500px;
}
