.integration-section {
	background-image: url('../../../images-2/businessLogo.svg');
	background-size: cover;
	background-position: center;
	border-radius: 20px;
	padding: 50px;
	position: relative;
	z-index: 1;
	margin-top: 100px;
	margin-bottom: 100px;

	.integration-content {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 20px;
	}

	.text-content {
		max-width: 50%;
		color: white;

		h2 {
			color: #fff;
			font-size: 40px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		p {
			color: #fff;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 144%; /* 28.8px */
			margin-top: 12px;

		}
	}
}
