.login-container {
	margin-top: 120px;
	display: flex;
	justify-content: space-between;
	gap: 60px;
}

.login-form {
	padding: 70px;
	gap: 24px;
	flex: 1 0 0;
	border-radius: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.login-form-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.input-block {
	margin-top: 24px;
}
.btn-block-login {
	position: relative;
}
.btn-block-login button {
	width: 100%;
}
.login-logo2 {
	position: absolute;
	top: 11px;
	right: 32px;
}

.complete {
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	margin-top: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.complete-text {
	width: 300px;
}
.complete-text span {
	color: var(--Success, #4fa21e);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 144%;
	text-decoration-line: underline;
}
.registr {
	margin-top: 80px;
	display: flex;
	gap: 80px;
	margin-bottom: 50px;
}

.form {
	display: flex;
	flex-direction: column;
	padding: 60px;
	gap: 24px;
	flex: 1 0 0;
	border-radius: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.complete-text-2 {
	text-align: center;
}
.register-text {
	text-align: center;
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
}
.register-text-2 {
	text-align: center;
	color: var(--subMain, #67686a);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
}
.or-block {
	display: flex;
	justify-content: center;
	align-items: center;
}
.or-text {
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
	width: 40px;
	height: 40px;
	gap: 10px;
	border-radius: 99px;
	background: #eee;
	display: flex;
	justify-content: center;
	align-items: center;
}
.register-form {
	margin-top: 80px;
}
.register-form {
	display: flex;
	justify-content: space-between;
	gap: 60px;
}
.registerForm {
	display: flex;
	padding: 40px 60px 60px 60px;
	flex-direction: column;
	gap: 24px;
	flex: 1 0 0;
	border-radius: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
	margin-bottom: 60px;
}
.register-form-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.first-input {
	display: flex;
	gap: 20px;
}
.account-type-container {
	max-width: 600px;
	margin: 0 auto;
	color: var(--subMain, #67686a);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 144%; /* 23.04px */
}

.account-type-title {
	font-weight: bold;
	margin-bottom: 16px;
}

.radio-group {
	display: flex;
	gap: 20px;
	margin-bottom: 16px;
}

.admin-label {
	display: flex;
	align-items: center;
}

.admin-icon {
	background-color: #4caf50;
	color: white;
	border-radius: 50%;
	padding: 4px 8px;
	margin-left: 8px;
}

.checkbox-group {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.back-to-login {
	color: var(--main, #000);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	display: flex;
	justify-content: center;
	gap: 15px;
	cursor: pointer;
}
