.section4 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	margin-top: 80px;
}

.section4-block {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 500px;
}
.section4-title {
	color: var(--main, #000);
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.section4-text {
	color: var(--subMain, #67686a);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%;
}
