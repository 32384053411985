.footer2 {
	padding: 20px 135px;
	background: #242424;
	box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.1);
	position: relative;
	bottom: 0;
}

.list-item {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.contacts-item {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
	align-items: center;
}
.footer-container-2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer-list {
	display: flex;
	align-items: center;
	gap: 32px;
	cursor: pointer;
}
.list-item:hover {
	color: #1183c3;
}
