.contact-title {
	color: var(--main, #000);
	text-align: center;
	font-size: 50px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.contact-text {
	color: var(--main, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	justify-content: center;
	gap: 15px;

}
.contact-page-container{
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-top: 150px;
}